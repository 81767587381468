import ajax from '@/utils/ajax.js'

// 获取列表
export function getList(params) {
  return ajax.post('/CouponEvent/getList',params)
}

/**
 * 修改状态，停用/恢复 
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/CouponEvent/upStatus',params)
}

// 添加活动
export function add(params) {
  return ajax.post('/CouponEvent/add',params)
}


// 活动详情
export function detail(params) {
  return ajax.post('/CouponEvent/detail',params)
}

// 修改活动
export function update(params) {
  return ajax.post('/CouponEvent/update',params)
}

