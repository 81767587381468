<template>
	<a-modal 
			:visible="show" 
			width="600px" 
			destroyOnClose
			:title="modalType=='edit' ? '编辑活动' : '创建活动'" 
			ok-text="保存" 
			@cancel="handleCancel" 
			@ok="handleAddSave">
		<a-form-model labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" ref="addModelRef" :model="addForm">
			<a-form-model-item :rules="requiredRule" prop="outlets_city_adcode" label="投放城市"
				v-if="type==2">
				<a-select style="width:330px"
						v-model="addForm.outlets_city_adcode" 
						:disabled="modalType!='add'"
						@change="cityChanged">
					<a-select-option value="">请选择</a-select-option>
					<a-select-option v-for="(item, index) in cityList" :key="index" 
						:value="item.adcode">{{ item.name }}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item :rules="requiredRule" prop="name" label="官方名称：">
				<a-input style="width:330px" placeholder="请输入官方名称" v-model="addForm.name" />
			</a-form-model-item>
			<a-form-model-item :rules="requiredRule" prop="name_interior" label="内部名称：">
				<a-input style="width:330px" placeholder="请输入内部名称" v-model="addForm.name_interior" />
			</a-form-model-item>
			<a-form-model-item :rules="requiredRule" prop="event_time" label="活动日期">
				<a-range-picker style="width:330px" v-model="addForm.event_time" valueFormat="YYYY-MM-DD" />
			</a-form-model-item>
			<template v-if="type==1">
				<a-form-model-item :rules="requiredRule" prop="coupon_names" label="优惠券：">
					<a-select v-model="addForm.coupon_names" mode="tags" style="width:330px; margin-right:20px;" placeholder="优惠券" :open="false" disabled></a-select>
					<a-button type="primary" @click="handlerAddCouponSelect">选择</a-button>
				</a-form-model-item>
				<a-form-model-item :rules="requiredRule" prop="partake_limit" label="参与次数：">
					<a-input-number style="width:130px" placeholder="参与次数" 
							v-model="addForm.partake_limit" :min="1" :step="1" />
					<span class="ml-2" style="color:#999">(每人可以参与几次)</span>
				</a-form-model-item>
			</template>
			<template v-if="type==2">
				<a-form-model-item :rules="requiredRule" prop="coupon_names" label="城市优惠券：">
					<a-select v-model="addForm.coupon_names" mode="tags" style="width:330px; margin-right:20px;" placeholder="优惠券" :open="false" disabled></a-select>
					<a-button type="primary" @click="handlerAddCouponSelect">选择</a-button>
				</a-form-model-item>
				<a-form-model-item :rules="requiredRule" prop="poster_path" :colon="false">
					<span slot="label">活动海报图:</span>
					<div class="flex">
						<upload-image type="45" :value.sync="addForm.poster_path" />
					</div>
				</a-form-model-item>
				<a-form-model-item label="">
					h5分享（如不填写则使用默认图片、标题）
				</a-form-model-item>
				<a-form-model-item prop="share_title" label="分享标题：">
					<a-input style="width:330px" placeholder="请输入分享标题" v-model="addForm.share_title" />
				</a-form-model-item>
				<a-form-model-item prop="share_describe" label="副标题：">
					<a-input style="width:330px" placeholder="请输入副标题" v-model="addForm.share_describe" />
				</a-form-model-item>
				<a-form-model-item prop="thumbnail_path" label="缩略图">
					<div class="flex">
						<upload-image type="45" :value.sync="addForm.thumbnail_path" />
					</div>
				</a-form-model-item>
			</template>
				
		</a-form-model>

		<!-- 选择券 -->
		<a-modal v-model="isShowSelectModal" width="900px" ok-text="选择" @ok="selectModalSave" :zIndex="3000" title="选择优惠券">
			<a-tabs type="card" class="w-full" @change="handlerTypeChangeModal" :activeKey="activeKeyModal">
				<a-tab-pane key="1" tab="未开始">
				</a-tab-pane>
				<a-tab-pane key="2" tab="进行中">
				</a-tab-pane>
			</a-tabs>
			<base-table ref="selectTable" rowKey="id" 
				:columnsData="couponsColumn" 
				:tableData="couponsTableData" 
				:customHeight="300" 
				:row-selection="rowSelection">
			</base-table>
		</a-modal>
	</a-modal>
</template>
<script>
import pageData from "../columns"
import { requiredRule } from "@/hooks/use-form-rules"
import { add, detail, update } from "@/api/activity/shop-coupon-event.js"
import { getList as getListCoupon } from "@/api/activity/coupon.js"
import UploadImage from "@/components/upload-image"

export default {
	components: { 
		UploadImage,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		type: { // 1商城 2城市
			type: [String, Number],
			default: ""
		},
		modalType: {
			type: String,
			default: ""
		},
		activeId: {
			type: [String, Number],
			default: "",
		},
		cityList: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			...pageData,
			requiredRule,

			addForm: {
				type: '',
				name: '',
				name_interior: '',
				outlets_city_adcode: '',

				start_time: '',
				end_time: '',

				event_time: [],

				coupon_names: [],
				coupon_data: [],
				poster_path: '',

				share_title: '',
				share_describe: '',
				thumbnail_path: '',

				partake_limit: 1,
			},

			// 选择券
			isShowSelectModal: false,
			couponsTableData: [],
			rowSelection: {
				selectedRow: [],
				selectedRowKeys: [],
				onSelect: (record, selected) => {
					// console.log('onSelect', record, selected)
					if (selected) {
						this.rowSelection.selectedRowKeys.push(record.id)
						this.rowSelection.selectedRow.push(record)
					} else {
						this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(el => el != record.id)
						this.rowSelection.selectedRow = this.rowSelection.selectedRow.filter(el => el.id != record.id)
					}
				},
				onSelectAll: (selected, selectedRows) => {
					// console.log('onselectAll', selected, selectedRows)
					if (selected) {
						this.rowSelection.selectedRow = selectedRows
						this.rowSelection.selectedRowKeys = selectedRows.map((item) => {
							return item.id
						})
					} else {
						this.rowSelection.selectedRow = []
						this.rowSelection.selectedRowKeys = []
					}
				}
			},

			activeKeyModal: '',
		}
	},
	mounted() {
		if(this.type == 2){
			if(this.cityList.length == 1){
				this.addForm.outlets_city_adcode = this.cityList[0].adcode
			}
		}

		if (this.modalType == "edit") {
			this.initDetail()
		}else{
			this.addForm.type = this.type
		}
	},
	methods: {
		async initDetail() {
			const { data, code } = await detail({
				id: this.activeId
			})
			if (code === 0) {
				const event_time = [data.start_time.split(' ')[0], data.end_time.split(' ')[0]]
				const coupon_names = data.coupon_list.map(el => {
					return el.coupon_name
				})
				this.addForm = {
					...data,
					event_time,
					coupon_names
				}

				// this.addForm.name = data.name
				// this.addForm.name_interior = data.name_interior
				// this.addForm.outlets_city_adcode = data.outlets_city_adcode

				// this.addForm.event_time = [data.start_time.split(' ')[0], data.end_time.split(' ')[0]]

				// let coupon_names = data.coupon_list.map(el => {
				// 	return el.coupon_name
				// })

				// this.addForm.coupon_names = coupon_names
				// this.addForm.coupon_list = data.coupon_list

				// this.addForm.poster_path = data.poster_path || ""

				// // 分享信息
				// this.addForm.share_title = data.share_title || ""
				// this.addForm.share_describe = data.share_describe || ""
				// this.addForm.thumbnail_path = data.thumbnail_path || ""

				this.$refs.addModelRef.clearValidate()
			}
		},
		cityChanged(){
			this.addForm.coupon_names = []
			this.addForm.coupon_list = []
		},

		handleCancel() {
			this.$emit('update:show', false)
		},
		handleAddSave() {
			this.$refs.addModelRef.validate((valid) => {
				if (valid) {
					const params = {
						...this.addForm,
						start_time: 			this.addForm.event_time[0] + ' 00:00:00',
						end_time: 				this.addForm.event_time[1] + ' 23:59:59',
						coupon_data: 	JSON.stringify(this.addForm.coupon_list),
					}
					// const params = {
					// 	type: 					this.addForm.type,
					// 	name: 					this.addForm.name,
					// 	name_interior: 			this.addForm.name_interior,
					// 	outlets_city_adcode: 	this.addForm.outlets_city_adcode,
					// 	start_time: 			this.addForm.event_time[0] + ' 00:00:00',
					// 	end_time: 				this.addForm.event_time[1] + ' 23:59:59',
					// 	poster_path: 			this.addForm.poster_path,
					// 	thumbnail_path: 		this.addForm.thumbnail_path,
					// 	share_title: 			this.addForm.share_title,
					// 	share_describe: 		this.addForm.share_describe,
					// 	coupon_data: 	JSON.stringify(this.addForm.coupon_list),
					// }
					if (this.modalType == 'add') {
						add(params).then((res) => {
							if (res.code === 0) {
								this.$message.success('添加成功')
								this.$emit("added")
							}
						})
					} else {
						params.id = this.activeId
						update(params).then((res) => {
							if (res.code === 0) {
								this.$message.success('编辑成功')
								this.$emit("updated")
							}
						})
					}
				}
			})
		},
		handlerAddCouponSelect() {
			if(this.type == 2){
				if(!this.addForm.outlets_city_adcode){
					this.$message.warning("请选择投放城市")
					return
				}
			}

			this.handlerTypeChangeModal('1')
			this.isShowSelectModal = true
		},
		async handlerTypeChangeModal(e) {
			if(this.type == 2){
				if(!this.addForm.outlets_city_adcode){
					this.$message.warning("请选择投放城市")
					return
				}
			}
			this.activeKeyModal = e
			const params = {
				belong: this.type,
				outlets_city_adcode: this.addForm.outlets_city_adcode,
				date_type: this.activeKeyModal,
				status: 1,
				admin_type: 1, // 写死普通类
			}
			const { data, code } = await getListCoupon(params)
			if (code === 0) {
				this.couponsTableData = data.list
			}
		},
		selectModalSave() {
			if (!this.rowSelection.selectedRowKeys.length) {
				this.$message.warning("请选择优惠券")
				return
			}
			let arr_names = []
			let arr_datas = []
			this.rowSelection.selectedRow.forEach(element => {
				arr_names.push(element.name)
				arr_datas.push({ coupon_id: element.id })
			});
			this.addForm.coupon_names = arr_names
			this.addForm.coupon_list = arr_datas

			this.isShowSelectModal = false
		},
	},
}
</script>
<style>
</style>