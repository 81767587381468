// import {formatGoodsType} from '@/utils/type'
export const rowKey = "id";
export const columns = [
  {
    title: "官方名称",
    align: "center",
    width: 140,
    dataIndex: "name"
  },
  {
    title: "内部备注名",
    align: "center",
    width: 140,
    dataIndex: "name_interior",
  },
  {
    title: "投放城市",
    align: "center",
    width: 90,
    dataIndex: "outlets_city_name",
  },
  {
    title: "活动开始",
    align: "center",
    // width: 170,
    dataIndex: "start_time",
  },
  {
    title: "活动结束",
    align: "center",
    // width: 170,
    dataIndex: "end_time",
  },
  {
    title: "活动状态",
    align: "center",
    // width: "8%",
    width: 80,
    dataIndex: "status",
    slots: {
      customRender: 'status'
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 180,
    // width: "13%",
    align: "center",
    slots: {
      customRender: "operation",
    },
  },
];


export const couponsColumn = [
  {
    title: "官方名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "内部备注名",
    dataIndex: "name_interior",
    align: "center",
  },
  {
    title: "有效期",
    dataIndex: "start_time",
    align: "center",
    slots: {
      customRender: 'start_time'
    },
    slotsType: 'all',
    slotsFunc: (row) => {
      return String(row.start_time).split(" ")[0] + " ~ " + String(row.end_time).split(" ")[0]
    }
  },
  {
    title: "面额",
    width: 70,
    dataIndex: "reduce_cost",
    align: "center",
  },
  {
    title: "使用门槛",
    width: 80,
    dataIndex: "least_cost",
    align: "center",
  }
]

const couponBelong = {
  1:'商城券',
  2:'城市券',
}
const couponType = {
  1:'普通券',
  2:'生日券',
  3:'补偿专用'
}

export function formatBelong (val) {
  return couponBelong[val]
}

export function formatType (val) {
  return couponType[val]
}


export default {
  rowKey,
  columns,
  couponsColumn
};
