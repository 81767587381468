import ajax from '@/utils/ajax.js'

// 获取列表
export function getList(params) {
  return ajax.post('/Coupon/getList',params)
}


// 获取详情
export function getDetailById(params) {
  return ajax.post('/Coupon/detail',params)
}

/**
 * 新增
 * @param {*} params 
 * @returns 
 */
export function add(params) {
  return ajax.post('/Coupon/add',params)
}

/**
 * 修改
 * @param {*} params 
 * @returns 
 */
export function edit(params) {
  return ajax.post('/Coupon/update',params)
}

/**
 * 修改状态，停用/恢复 
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/Coupon/upStatus',params)
}


/**
 * 优惠券 领/用/剩余 数量
 * @param {*} params 
 * @returns 
 */
export function getCountInfo(params) {
  return ajax.post('/Coupon/recordCount',params)
}


// 获取领取记录
export function getRecord(params) {
  return ajax.post('/Coupon/recordQuery',params)
}

// 导出领取/使用记录
export function exportRecord(params) {
  return ajax.openurl('/Coupon/recordExport',params)
}

export function getGoodsList(params){
  return ajax.post('/Coupon/getGoodsListAddGoodsUse', params)
}


// 获取批次列表
export function couponStockGetList(params) {
  return ajax.post('/CouponStock/getList',params)
}

// 创建批次
export function couponStockCreate(params) {
  return ajax.post('/CouponStock/create',params)
}

// 批次码导出
export function couponStockCodeExport(params) {
  return ajax.openurl('/CouponStock/codeExport',params)
}
